import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {StoreService} from '../../domain/store.service';
import {ActivatedRoute} from '@angular/router';
import {CustomerService} from '../../domain/customer.service';
import {Store} from '../../domain/models/store/store';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login.dialog.html',
  styleUrls: ['./login.dialog.sass'],
})
export class LoginDialog implements OnInit {

  readonly phoneLoginKey = 'sms';
  readonly vippsLoginKey = 'vipps';
  readonly googleLoginKey = 'google';

  isLoading = false;
  enablePhoneLogin = false;
  enableVippsLogin = false;
  enableGoogleLogin = false;
  showNoLoginMethods = false;

  constructor(private dialogRef: MatDialogRef<LoginDialog, LoginDialogResult>,
              @Inject(MAT_DIALOG_DATA) public data: { state?: LoginDialogState } | undefined,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private ngZone: NgZone,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private customerService: CustomerService,
  ) {
  }

  async ngOnInit() {
    this.isLoading = true;
    let storeHandle = null;

    if (this.storeService.storeId) {
      storeHandle = this.storeService.storeId;
    } else if (this.route?.firstChild?.snapshot.paramMap.get('id') != null) {
      storeHandle = this.route.firstChild.snapshot.paramMap.get('id');
    }

    if (storeHandle) {
      const store = await this.storeService.getStore(storeHandle);
      await this.configureLoginMethods(store);
    }

    if (this.data?.state == LoginDialogState.LoginExpired) {
      this.toastr.warning(
        await this.translateService.get('LOGIN.ERROR.expired').toPromise(),
        undefined,
        {timeOut: 5000, easeTime: 100, positionClass: 'toast-bottom-center'},
      );
    } else if (this.data?.state == LoginDialogState.LoginRequired) {
      this.toastr.warning(
        await this.translateService.get('LOGIN.ERROR.required').toPromise(),
        undefined,
        {timeOut: 5000, easeTime: 100, positionClass: 'toast-bottom-center'},
      );
    }
    this.isLoading = false;
  }

  private async configureLoginMethods(store: Store) {
    const loginMethods = await this.customerService.getLoginMethods(store.handle);

    if (loginMethods && loginMethods.length === 0) {
      this.showNoLoginMethods = true;
    } else {
      this.enableVippsLogin = loginMethods.some(loginMethod => loginMethod.type === this.vippsLoginKey);
      this.enablePhoneLogin = loginMethods.some(loginMethod => loginMethod.type === this.phoneLoginKey);
      this.enableGoogleLogin = loginMethods.some(loginMethod => loginMethod.type === this.googleLoginKey);
    }
  }

  reject() {
    this.dialogRef.close(LoginDialogResult.Rejected);
  }

  async onLogin() {
    const message = await this.translateService.get('LOGIN.loggedIn').toPromise();
    this.dialogRef.afterClosed().subscribe(() => {
      this.toastr.success(
        message,
        undefined,
        {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'},
      );
    });

    // Dialog is opened outside the NgZone for some reason
    this.ngZone.run(() => {
      this.dialogRef.close(LoginDialogResult.Success);
    });
  }

  onLoading(state: boolean) {
    this.isLoading = state;
  }
}

export enum LoginDialogState {
  LoginExpired = 'expired',
  LoginRequired = 'required',
}

export enum LoginDialogResult {
  Success = 'success',
  Rejected = 'rejected',
}
