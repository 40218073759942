import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerService} from '../../domain/customer.service';
import {TranslateService} from '@ngx-translate/core';
import {ResourceService} from '../../domain/resource.service';
import {CountryPhoneCode} from '../../domain/models/resource/country-phone-code';
import {StoreService} from '../../domain/store.service';
import {Store} from '../../domain/models/store/store';
import {StoreProfile} from '../../domain/models/store/store-profile';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  @Output() loggedIn = new EventEmitter();
  @Output() isLoading = new EventEmitter<boolean>();
  @ViewChild('codeInput') inputView?: ElementRef;

  phoneNumber?: string;
  smsCode?: string;
  wrongFormat = false;
  numberNotEntered = false;
  store?: Store;
  profile?: StoreProfile;
  loginState = LoginState;
  state: LoginState = LoginState.logout;
  selectedCountry?: CountryPhoneCode;
  countries?: CountryPhoneCode[];
  wrongCodeMessage?: boolean;

  constructor(private customerService: CustomerService,
              private translateService: TranslateService,
              private resourceService: ResourceService,
              private storeService: StoreService) {
  }

  async ngOnInit() {
    this.countries = await this.getCountries();
    this.selectedCountry = this.countries?.find(country =>
      country.countryCode == this.translateService.currentLang.toUpperCase(),
    );

    if (this.storeService.storeId) {
      this.store = await this.storeService.getStore(this.storeService.storeId);
      this.profile = this.store.storeProfile;
    }

  }

  async sendSmsCode(number: string) {
    this.wrongFormat = false;
    this.numberNotEntered = false;

    const countryCode = this.selectedCountry;

    if (!(countryCode && number)) {
      // missing countryCode or number
      this.numberNotEntered = true;
      return;
    }

    const phoneNumber = number.replace(/\s/g, ''); // trim whitespaces
    if (!RegExp('^\\d*$').exec(phoneNumber)) {
      // phoneNumber contains more than numbers
      this.wrongFormat = true;
      return;
    }

    if (phoneNumber.length < countryCode.minLength || phoneNumber.length > countryCode.maxLength) {
      this.wrongFormat = true;
      return;
    }

    this.isLoading.emit(true);
    await this.customerService
      .customerSmsLogin(countryCode.number + number)
      .finally(() => this.isLoading.emit(false));
    this.state = LoginState.login;
    setTimeout(() => this.inputView?.nativeElement.focus());
  }

  async enterSmsCode(smsCode: string) {
    this.wrongCodeMessage = false;
    try {
      if (this.phoneNumber && smsCode) {
        this.isLoading.emit(true);
        await this.customerService
          .customerSmsAuthenticate(this.selectedCountry?.number + this.phoneNumber, smsCode)
          .finally(() => this.isLoading.emit(false));
        this.loggedIn.emit();
      }
    } catch (e) {
      if (e.error?.status == 401) {
        this.wrongCodeMessage = true;
      } else {
        throw e;
      }
    }
  }

  getCountries(): Promise<CountryPhoneCode[]> {
    return this.resourceService.getCountryPhoneCodes();
  }
}

enum LoginState {
  logout,
  login,
}
