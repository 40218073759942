import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {Store} from '../../../domain/models/store/store';
import {ForegroundPaths} from '../../../app-routing.module';
import {Order} from '../../../domain/models/order/order';
import {OrderUtils} from '../../../utils/order.utils';
import {OrderService} from '../../../domain/order.service';
import {CustomerService} from '../../../domain/customer.service';
import {httpUtils} from '../../../utils/http.utils';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cash-payment',
  templateUrl: './cash-payment.component.html',
  styleUrls: ['./cash-payment.component.sass'],
})
export class CashPaymentComponent implements OnInit {
  order?: Order;
  store?: Store;
  profile?: StoreProfile;
  isBusy = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private storeService: StoreService,
              private orderService: OrderService,
              private customerService: CustomerService,
  ) {
  }

  async ngOnInit() {
    const storeHandle = this.route.parent?.firstChild?.snapshot.paramMap.get('id');
    const orderId = this.route.snapshot.paramMap.get('orderId');
    if (!storeHandle) {
      throw Error('Missing storeHandle');
    } else if (!orderId) {
      throw Error('Missing orderId');
    }

    this.store = await this.storeService.getStore(storeHandle);
    this.profile = this.store.storeProfile;

    const customerId = await this.customerService.getCustomerId();

    try {
      this.order = await this.orderService.getOrder(customerId, orderId);
    } catch (error) {
      if (httpUtils(error).isStatus(404, 401)) {
        this.toastr.error(this.translateService.instant('PAYMENT.orderNotFound'), undefined, {
          timeOut: 5000,
          easeTime: 100,
          positionClass: 'toast-bottom-center',
        });
        this.router.navigate(ForegroundPaths.empty());
      }
      throw error;
    } finally {
      this.isBusy = false;
    }

    if (OrderUtils.isPaid(this.order)) {
      await this.navigateToReceipt();
    }
  }

  async commit() {
    this.isBusy = true;
    await this.navigateToReceipt();
  }

  cancel() {
    this.router.navigate(ForegroundPaths.cartPayment());
  }

  async navigateToReceipt() {
    await this.router.navigate(ForegroundPaths.empty());
    await this.router.navigate([`/store/${this.store?.handle}/receipt/${this.order?.id}`]);
  }
}
