import {Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';
import {CodeType, ScannerService} from '../../../domain/scanner.service';
import {interval, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ForegroundPaths} from '../../../app-routing.module';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {isFailed, isPaid} from '../../../utils/order.utils';
import {PaymentService} from '../../../domain/payment.service';
import {concatMap, map, take} from 'rxjs/operators';
import {PaymentIdentifier} from '../../../domain/models/payment/payment-method';
import {CartService} from '../../../domain/cart.service';
import {Cart} from '../../../domain/models/order/cart';
import {delay} from '../../../utils/promise.utils';

@Component({
  selector: 'app-softpay-payment',
  templateUrl: './softpay-payment.component.html',
  styleUrls: ['./softpay-payment.component.sass'],
})
export class SoftpayPaymentComponent implements OnInit, OnDestroy {
  cart!: Cart;
  store!: Store;
  profile?: StoreProfile;
  minimized = false;
  isPolling = false;
  isRetry = false;

  private scannerSubscription?: Subscription;
  private pollingSubscription?: Subscription;
  readonly MAX_POLLING_ATTEMPTS = 60 * 5;

  readonly E_INVALID_QR = 'Invalid QR code';
  readonly E_INCORRECT_LOCATION = 'Location does not match QR´s location';

  constructor(private storeService: StoreService,
              private scannerService: ScannerService,
              private cartService: CartService,
              private paymentService: PaymentService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private translateService: TranslateService,
  ) {
  }

  async ngOnInit() {
    const storeHandle = this.route.parent?.firstChild?.snapshot.paramMap.get('id');
    if (!storeHandle) {
      throw Error('Missing storeHandle');
    }

    this.store = await this.storeService.getStore(storeHandle);
    this.profile = this.store.storeProfile;

    await this.initCart();
  }

  async initCart() {
    this.cart = await this.cartService.getOrCreateLocalCart(this.store.handle);

    if (!this.cart.orderId) {
      this.toastr.warning(this.translateService.instant('PAYMENT.orderNotFound'), undefined, {
        timeOut: 5000,
        easeTime: 100,
        positionClass: 'toast-bottom-center',
      });
      this.router.navigate(ForegroundPaths.empty());
      throw Error('Cart does not have an orderId');
    }
  }

  async startScan() {
    this.scannerSubscription = this.scannerService
      .onScanResult([CodeType.QR], this.translateService.instant('PAYMENT.terminalScannerHelp'))
      .subscribe(async result => {
        this.scannerSubscription?.unsubscribe();

        // PARSE QR
        let deviceId: string;
        try {
          deviceId = this.parseBarcodeUrl(result.barcodeData);
        } catch (error) {
          if (error?.message == this.E_INCORRECT_LOCATION) {
            window.location.href = result.barcodeData;
            throw error;
          } else if (error?.message == this.E_INVALID_QR) {
            this.toastr.warning(this.translateService.instant('PAYMENT.invalidQR'), undefined, {
              timeOut: 3000,
              easeTime: 100,
              positionClass: 'toast-bottom-center',
            });
            await this.startScan();
          } else {
            this.expand();
            throw error;
          }
          return;
        }

        // INIT PAYMENT
        try {
          await this.paymentService.initialize(PaymentIdentifier.Softpay, this.cart, deviceId);
          this.startPolling();
        } catch (error) {
          try {
            await PaymentService.errorHandler(error, {
              orderIsPaid: async () => {
                await this.navigateToReceipt().then(() => this.cartService.clearCart(this.store.handle));
              },
              productNotFound: async () => {
                this.cartService.clearCart(this.store.handle);
                await this.router.navigate(ForegroundPaths.paymentFailed());
              },
              dimensionOutOfStock: message => {
                this.expand();
                this.toastr.warning(message, undefined, {
                  timeOut: 3000,
                  easeTime: 100,
                  positionClass: 'toast-bottom-center',
                });
              },
            });
          } catch (rethrownError) {
            await delay(1000);
            this.router.navigate(ForegroundPaths.paymentFailed()).then(() => {
              // Hack in order to restore related products in cart:
              window.location.reload();
            });
          }

          throw error;
        }
      });
  }

  private parseBarcodeUrl(barcodeData: string) {
    let url: URL;
    try {
      url = new URL(barcodeData);
    } catch (e) {
      throw Error(this.E_INVALID_QR);
    }

    const deviceId = url.searchParams.get('deviceId');
    if (deviceId && (window.location.hostname != url.hostname || !window.location.pathname.includes(url.pathname))) {
      throw Error(this.E_INCORRECT_LOCATION);
    } else if (deviceId) {
      return deviceId;
    }

    throw Error(this.E_INVALID_QR);
  }

  minimizeAndStartScanner() {
    this.minimized = true;
    this.router.navigate([`/store/${this.store.handle}`], {relativeTo: this.route.parent?.firstChild}); // Hide "browse"
    this.startScan();
  }

  startPolling() {
    this.isPolling = true;
    this.expand();
    this.pollingSubscription?.unsubscribe();
    this.pollingSubscription = interval(1000)
      .pipe(
        concatMap(() => this.paymentService.refreshPaymentStatus(this.cart.orderId!)),
        map((transaction) => transaction),
        take(this.MAX_POLLING_ATTEMPTS),
      )
      .subscribe({
        next: (transaction) => {
          if (isPaid(transaction)) {
            this.cancelPolling();
            this.navigateToReceipt();
          } else if (isFailed(transaction)) {
            this.cancelPolling();
            this.router.navigate(ForegroundPaths.paymentFailed());
          }
        },
        complete: () => {
          this.cancelPolling();
          this.isRetry = true;
        },
        error: (error) => {
          this.cancelPolling();
          throw error;
        },
      });
  }

  cancelPolling() {
    this.isPolling = false;
    this.pollingSubscription?.unsubscribe();
  }

  expand() {
    this.scannerSubscription?.unsubscribe();
    this.minimized = false;
  }

  back() {
    this.router.navigate(ForegroundPaths.cartPayment());
  }

  async retry() {
    this.isRetry = false;
    this.isPolling = true;
    await this.initCart(); // Make sure Cart is not outdated
    this.startPolling();
  }

  private async navigateToReceipt() {
    await this.router.navigate(ForegroundPaths.empty());
    await this.router.navigate([`/store/${this.store.handle}/receipt/${this.cart.orderId}`]);
  }

  ngOnDestroy() {
    this.pollingSubscription?.unsubscribe();
    this.scannerSubscription?.unsubscribe();
  }
}
