import {NgModule} from '@angular/core';
import {DefaultUrlSerializer, RouterModule, Routes, UrlSerializer, UrlTree} from '@angular/router';
import {StoreDetailsComponent} from './pages/background/store-details/store-details.component';
import {CartComponent} from './pages/foreground/cart/cart.component';
import {ProductDetailsComponent} from './pages/foreground/product-details/product-details.component';
import {CartDetailsComponent} from './pages/foreground/cart-details/cart-details.component';
import {OrderListComponent} from './pages/background/order-list/order-list.component';
import {OrderDetailsComponent} from './pages/background/order-details/order-details.component';
import {ScanWeightForOrderLineComponent} from './pages/foreground/scan-weight-for-orderline/scan-weight-for-orderline.component';
import {PrivacyPolicyComponent} from './pages/background/privacy-policy/privacy-policy.component';
import {ShoppingBagComponent} from './pages/foreground/shopping-bag/shopping-bag.component';
import {AdjustmentTypeChooserComponent} from './pages/foreground/adjustment-type-chooser/adjustment-type-chooser.component';
import {PaymentFailedComponent} from './pages/foreground/payment-failed/payment-failed.component';
import {AgeLimitComponent} from './pages/foreground/age-limit/age-limit.component';
import {CartPaymentComponent} from './pages/foreground/cart-payment/cart-payment.component';
import {BehindCounterMenuComponent} from './pages/foreground/behind-counter-menu/behind-counter-menu.component';
import {VippsAuthComponent} from './pages/background/vipps-auth/vipps-auth.component';
import {ReceiptPageComponent} from './pages/background/receipt-page/receipt-page.component';
import {FygiPageComponent} from './pages/background/fygi-page/fygi-page.component';
import {CheckoutReceiptComponent} from './pages/foreground/checkout-receipt/checkout-receipt.component';
import {KebabFridayComponent} from './pages/foreground/easter-eggs/kebab-friday/kebab-friday.component';
import {DoorComponent} from './pages/background/door/door.component';
import {PageNotFoundComponent} from './pages/background/page-not-found/page-not-found.component';
import {InspectionComponent} from './pages/foreground/inspection/inspection.component';
import {CashPaymentComponent} from './pages/foreground/cash-payment/cash-payment.component';
import {SoftpayPaymentComponent} from './pages/foreground/softpay-payment/softpay-payment.component';

export const foregroundOutletName = 'foreground-outlet';

const routes: Routes = [
  {path: '', component: FygiPageComponent},
  {path: 'door/:id', component: DoorComponent},
  {path: 'orders', component: OrderListComponent},
  {path: 'orders/:id', component: OrderDetailsComponent},
  {path: 'store/:id', component: StoreDetailsComponent},
  {path: 'store', component: StoreDetailsComponent},
  {path: 'store/:id/browse', component: StoreDetailsComponent},
  {path: 'store/:id/microshop/:microshopHandle/browse', component: StoreDetailsComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'vipps-login', component: VippsAuthComponent},
  {path: 'store/:id/browse/receipt/:orderId', component: ReceiptPageComponent},
  {path: 'store/:id/browse/receipt/:orderId/transactionid/:transactionId', component: ReceiptPageComponent},
  {path: 'store/:id/receipt/:orderId', component: ReceiptPageComponent},
  {path: 'store/:id/receipt/:orderId/transactionid/:transactionId', component: ReceiptPageComponent},

  {path: '', component: CartComponent, outlet: foregroundOutletName},
  {path: 'product/:productId', component: ProductDetailsComponent, outlet: foregroundOutletName},
  {path: 'product/:productId/:dimensionId', component: ProductDetailsComponent, outlet: foregroundOutletName},
  {path: 'cart', component: CartDetailsComponent, outlet: foregroundOutletName},
  {path: 'cart/payment', component: CartPaymentComponent, outlet: foregroundOutletName},
  {path: 'cart/payment/inspect/:orderId', component: InspectionComponent, outlet: foregroundOutletName},
  {path: 'cart/payment/cash/:orderId', component: CashPaymentComponent, outlet: foregroundOutletName},
  {path: 'cart/payment/softpay', component: SoftpayPaymentComponent, outlet: foregroundOutletName},
  {path: 'cart/shoppingbag', component: ShoppingBagComponent, outlet: foregroundOutletName},
  {path: 'order/:id', component: OrderDetailsComponent, outlet: foregroundOutletName},
  {path: 'cart/receipt/:orderId', component: CheckoutReceiptComponent, outlet: foregroundOutletName},
  {path: 'cart/paymentcanceled', component: PaymentFailedComponent, outlet: foregroundOutletName},
  {path: 'weight/scan/:orderLineId', component: ScanWeightForOrderLineComponent, outlet: foregroundOutletName},
  {path: 'choose', component: AdjustmentTypeChooserComponent, outlet: foregroundOutletName},
  {path: 'choose/:productHandle', component: AdjustmentTypeChooserComponent, outlet: foregroundOutletName},
  {path: 'cart/agelimit', component: AgeLimitComponent, outlet: foregroundOutletName},
  {path: 'behind-counter', component: BehindCounterMenuComponent, outlet: foregroundOutletName},
  {path: 'eastereggs/kebabfriday', component: KebabFridayComponent, outlet: foregroundOutletName},
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

export class ForegroundPaths {

  static empty(): any[] {
    return [{outlets: {'foreground-outlet': null}}];
  }

  static cart(): any[] {
    return [{outlets: {'foreground-outlet': 'cart'}}];
  }

  static behindCounter(): any[] {
    return [{outlets: {'foreground-outlet': 'behind-counter'}}];
  }

  static cartPayment(): any[] {
    return [{outlets: {'foreground-outlet': 'cart/payment'}}];
  }

  static cartPaymentCash(orderId: string): any[] {
    return [{outlets: {'foreground-outlet': `cart/payment/cash/${orderId}`}}];
  }

  static cartPaymentSoftpay(): any[] {
    return [{outlets: {'foreground-outlet': `cart/payment/softpay`}}];
  }

  static inspection(orderId: string): any[] {
    return [{outlets: {'foreground-outlet': `cart/payment/inspect/${orderId}`}}];
  }

  static adjustmentChooser(): any[] {
    return [{outlets: {'foreground-outlet': 'choose'}}];
  }

  static adjustmentChooserWithProduct(productId: string): any[] {
    return [{outlets: {'foreground-outlet': `choose/${productId}`}}];
  }

  static orderDetails(orderId: string): any[] {
    return [{outlets: {'foreground-outlet': `order/${orderId}`}}];
  }

  static shoppingBag(): any[] {
    return [{outlets: {'foreground-outlet': 'cart/shoppingbag'}}];
  }

  static product(handle: string): any[] {
    return [{outlets: {'foreground-outlet': `product/${handle}`}}];
  }

  static productWithDimension(handle: string, dimensionId: string | undefined): any[] {
    return [{outlets: {'foreground-outlet': `product/${handle}/${dimensionId}`}}];
  }

  static category(handle: string): any[] {
    return [{outlets: {'foreground-outlet': `category/${handle}`}}];
  }

  static receipt(orderId: string, transactionId: string) {
    return [{outlets: {'foreground-outlet': `receipt/${orderId}/transactionid/${transactionId}`}}];
  }

  static cartCheckout(orderId: string): any[] {
    return [{outlets: {'foreground-outlet': `cart/receipt/${orderId}`}}];
  }

  static weighProduct(weightInGrams: number): any[] {
    return [{outlets: {'foreground-outlet': `weight/${weightInGrams}`}}];
  }

  static scanWeightForOrderLine(orderLineId: string): any[] {
    return [{outlets: {'foreground-outlet': `weight/scan/${orderLineId}`}}];
  }

  static ageLimit(): any[] {
    return [{outlets: {'foreground-outlet': 'cart/agelimit'}}];
  }

  static paymentFailed(): any[] {
    return [{outlets: {'foreground-outlet': 'cart/paymentcanceled'}}];
  }

  static kebabFriday(): any[] {
    return [{outlets: {'foreground-outlet': 'eastereggs/kebabfriday'}}];
  }

}

export class ForegroundUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    let queryParams = '';

    if (url == '/store/parkering/browse') {
      window.location.href = 'https://fygi.app/store/parkering/microshop/parkering/browse';
    }

    if (url == '/store/liten-kiosk/browse') {
      window.location.href = 'https://fygi.app/store/liten-kiosk/microshop/liten-kiosk/browse';
    }

    if (url == '/store/stor-kiosk/browse') {
      window.location.href = 'https://fygi.app/store/stor-kiosk/microshop/stor-kiosk/browse';
    }

    if (url == '/store/hj-rnekiosk/browse') {
      window.location.href = 'https://fygi.app/store/hj-rnekiosk/microshop/hj-rnekiosk/browse';
    }

    if (url == '/store/merchandise/browse') {
      window.location.href = 'https://fygi.app/store/merchandise/microshop/merchandise/browse';
    }

    if (url == '/store/heiskiosk/browse') {
      window.location.href = 'https://fygi.app/store/heiskiosk/microshop/heiskiosk/browse';
    }

    if (url == '/store/biletter/browse') {
      window.location.href = 'https://fygi.app/store/biletter/microshop/biletter/browse';
    }

    if (url !== '' && !url.startsWith('/.well-known')) {
      [url, queryParams] = url.split('?');
      url = url.toLowerCase(); // Force all URL segments to lowercase. Ref. SCAN-2483
      queryParams = queryParams ? `?${queryParams}` : '';
      const paths = url.split('/');

      for (let i = 0; i < paths.length; i++) {
        if (
          [
            'product',
            'order',
            'cart',
            'behind-counter',
            'shoppingbag',
            'category',
            'weight',
            'chain',
            'choose',
          ].includes(paths[i])
        ) {
          const tail = `/${paths.slice(i, paths.length).join('/')}`;
          url = url.replace(tail, `(${foregroundOutletName}:${tail})`);
          break;
        }
      }
    }
    return this.defaultUrlSerializer.parse(url + queryParams);
  }

  serialize(tree: UrlTree): string {
    let url = this.defaultUrlSerializer.serialize(tree);
    const reg = /(\((foreground-outlet)).*(\))/;
    if (reg.test(url)) {
      const tail = `/${reg.exec(url)?.[0].replace(')', '').split(':')[1]}`;
      url = url.replace(reg, tail);
    }
    return url;
  }
}
