import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from "../../../domain/store.service";
import {StoreShallow} from "../../../domain/models/store/store";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {CodeType, ScannerService} from "../../../domain/scanner.service";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-store-list.view',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.sass']
})
export class StoreListComponent implements OnInit, OnDestroy {
  image = 'src/assets/images/avatar.svg';
  isScanningForStore = false;
  storeScanningSubscription?: Subscription;

  constructor(private router: Router,
              private ngZone: NgZone,
              private scannerService: ScannerService,
              private storeService: StoreService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private titleService: Title
  ) {
  }

  async ngOnInit() {
    await this.startScanForStore();
    this.translateService.get('STORE.title').toPromise()
      .then(title => this.titleService.setTitle(`${title} | FYGI`));
  }

  ngOnDestroy(): void {
    this.storeScanningSubscription?.unsubscribe();
  }

  async openStore(store: StoreShallow) {
    await this.router.navigate([`/store/${store.handle}`]);
  }

  async startScanForStore() {
    const tutorialText = await this.translateService.get('SCANNER.STORE.title').toPromise();
    this.isScanningForStore = true;
    this.storeScanningSubscription = this.scannerService
      .onScanResult([CodeType.QR], tutorialText)
      .subscribe(async result => {
        try {
          this.storeScanningSubscription?.unsubscribe();
          const storeId = result.barcodeData.split('/').slice(-1)[0];
          const store = await this.storeService.getStore(storeId).catch(_ => undefined);
          if (store) {
            await this.openStore(store);
          } else {
            const message = await this.translateService.get('SCANNER.ERROR.storelist').toPromise();
            this.toastr.warning(message, undefined, {
              timeOut: 3000,
              easeTime: 100,
              positionClass: 'toast-bottom-center'
            });
            await this.startScanForStore();
          }
        } catch (e) {
          await this.startScanForStore();
        }
      });
  }

  stopScanForStore() {
    this.isScanningForStore = false;
    this.storeScanningSubscription?.unsubscribe();
  }
}
