import {Component, EventEmitter, Input, NgZone, Output, Renderer2} from '@angular/core';
import {LoadJsFilesService} from '../../domain/load-js-files.service';
import {environment} from '../../../environments/environment';
import {PaymentService} from '../../domain/payment.service';
import {GooglePaySessionRespond} from '../../transport/models/payment/google-pay-session.respond';
import {InitializePaymentResponse} from '../../transport/models/payment/initialize-payment.response';
import {Cart} from '../../domain/models/order/cart';
import {PaymentIdentifier, PaymentMethod} from '../../domain/models/payment/payment-method';
import {Router} from '@angular/router';
import {ApplePaySessionRespond} from '../../transport/models/payment/apple-pay-session.respond';
import {TranslateService} from '@ngx-translate/core';
import {ForegroundPaths} from '../../app-routing.module';
import {delay} from '../../utils/promise.utils';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-vr-pay[storeHandle][cart][vrPaymentMethods]',
  templateUrl: 'vr-pay.component.html',
  styleUrls: ['vr-pay.component.sass'],
})
export class VrPayComponent {
  @Input() storeHandle!: string;
  @Input() cart!: Cart;
  @Input() vrPaymentMethods!: PaymentMethod[];
  @Output() isReadyToPay = new EventEmitter<boolean>();

  // FIXME: VR now requires a "checkoutId" token in order to load the JS widget
  readonly vrPayTestLibrary = 'https://test.vr-pay-ecommerce.de/v1/paymentWidgets.js';
  readonly vrPayProdLibrary = 'https://vr-pay-ecommerce.de/v1/paymentWidgets.js';

  applePaySessionInfo?: ApplePaySessionRespond;
  googlePaySessionInfo?: GooglePaySessionRespond;
  initializeResponse?: InitializePaymentResponse;
  scriptElement?: HTMLScriptElement;
  vrBrands = new Map<VrBrand, PaymentMethod>();
  formActionRoute?: string;
  formDataBrands?: string;
  isPaymentBusy = false;
  wpwlOptions!: WpwlOptions;

  constructor(
    private renderer: Renderer2,
    private loadJsFilesService: LoadJsFilesService,
    private paymentService: PaymentService,
    private router: Router,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private toastr: ToastrService,
  ) {
  }

  async ngOnInit() {
    this.formActionRoute = this.router.url.includes(`${this.storeHandle}/browse`) ?
      `${window.location.origin}/store/${this.storeHandle}/browse/receipt/${this.cart.orderId}` :
      `${window.location.origin}/store/${this.storeHandle}/receipt/${this.cart.orderId}`;

    this.vrPaymentMethods.forEach(method => {
      if (method.identifier == PaymentIdentifier.VrApplePay) {
        this.vrBrands.set(VrBrand.ApplePay, method);
      } else if (method.identifier == PaymentIdentifier.VrGooglePay) {
        this.vrBrands.set(VrBrand.GooglePay, method);
      }
    });

    if (this.vrBrands.size == 0) {
      throw new Error('None of the payment methods matched a VR brand');
    }

    this.formDataBrands = this.vrBrandsToString();

    this.initVrPayOptions();

    if (this.vrBrands.has(VrBrand.ApplePay)) {
      await this.initApplePay();
    }

    if (this.vrBrands.has(VrBrand.GooglePay)) {
      await this.initGooglePay();
    }

    (window as any).wpwlOptions = this.wpwlOptions;

    this.loadJsFilesService.loadJsScript(
      this.renderer,
      environment.environmentName == 'prod' ? this.vrPayProdLibrary : this.vrPayTestLibrary,
    )
      .then(script => this.scriptElement = script)
      .catch(() => {
        this.isReadyToPay.emit(false);
        throw new Error('VR Pay library not loaded');
      });
  }

  initVrPayOptions() {
    this.wpwlOptions = {
      locale: this.translateService.currentLang,
      createCheckout: async json => {
        if (this.isPaymentBusy) {
          throw new Error('VR payment already in progress');
        }

        const paymentMethod = this.vrBrands.get(json.brand);

        if (paymentMethod == undefined) {
          throw new Error('The VR brand does not have an associated payment method set');
        }

        this.isPaymentBusy = true;

        try {
          this.initializeResponse = await this.paymentService.initialize(paymentMethod.identifier, this.cart);
        } catch (errorResponse) {
          this.isPaymentBusy = false;
          // Must be run async
          PaymentService.errorHandler(errorResponse, {
            orderIsPaid: async () => {
              // Order has already been paid; do a full page reload to recover state
              await this.router.navigate(ForegroundPaths.empty());
              await delay(3000);
              window.location.reload();
            },
          });

          throw errorResponse;
        }

        this.isPaymentBusy = false;
        return this.initializeResponse.data?.checkoutId;
      },
      onReady: () => {
        this.ngZone.run(() => delay(1200).then(() => this.isReadyToPay.emit(true)));
      },
      onError: error => {
        this.ngZone.run(() => {
          this.isPaymentBusy = false;

          if (error?.event == 'closed') {
            return;
          }

          if (error?.name == 'InvalidCheckoutIdError') {
            this.translateService.get('ERROR.sessionExpired').toPromise().then(text => {
              this.toastr.error(text, undefined, {easeTime: 100, positionClass: 'toast-bottom-center'});
            });
          } else if (
            typeof error?.event == 'string' ||
            error?.name == 'PciIframeSubmitError' ||
            error?.name == 'PciIframeCommunicationError'
          ) {
            this.translateService.get('ERROR.GENERIC.description').toPromise().then(text => {
              this.toastr.error(text, undefined, {easeTime: 100, positionClass: 'toast-bottom-center'});
            });
          }

          throw new Error(`VR Pay onError: ${JSON.stringify(error)}`);
        });
      },
    };
  }

  async initApplePay() {
    this.applePaySessionInfo = await this.paymentService.applePayInitialize(this.storeHandle);

    this.wpwlOptions.applePay = {
      merchantCapabilities: this.applePaySessionInfo?.merchantCapabilities,
      supportedNetworks: this.applePaySessionInfo?.supportedNetworks,
      currencyCode: this.applePaySessionInfo?.currency,
      countryCode: this.applePaySessionInfo?.countryCode,
      buttonType: 'buy', // VR does not support type "pay"
      buttonStyle: 'black',
      total: {
        label: await this.translateService.get('CART.payNow').toPromise(),
        amount: (this.cart.sum / 100).toString(),
        type: 'final',
      },
      onCancel: () => this.ngZone.run(() => this.isPaymentBusy = false),
    };
  }

  async initGooglePay() {
    this.googlePaySessionInfo = await this.paymentService.googlePayInitialize(this.storeHandle);

    this.wpwlOptions.googlePay = {
      gatewayMerchantId: this.googlePaySessionInfo.gatewayMerchantId,
      merchantId: this.googlePaySessionInfo.merchantId,
      allowedAuthMethods: this.googlePaySessionInfo.allowedAuthMethods,
      allowedCardNetworks: this.googlePaySessionInfo.allowedCardNetworks,
      currencyCode: this.googlePaySessionInfo.currency,
      buttonType: 'pay',
      amount: (this.cart.sum / 100).toString(),
      onCancel: () => this.ngZone.run(() => this.isPaymentBusy = false),
    };
  }

  ngOnDestroy() {
    if ((window as any).wpwl !== undefined && (window as any).wpwl.unload !== undefined) {
      (window as any).wpwl.unload();
    }

    delete (window as any).wpwlOptions;

    if (this.scriptElement?.src) {
      this.loadJsFilesService.removeJsScript(this.scriptElement.src);
    }

    document.querySelectorAll('script[src*="vr-pay-ecommerce.de/"], script[src*="oppwa.com/"]').forEach(script => script.remove());
  }

  vrBrandsToString() {
    return Array.from(this.vrBrands.keys())
      .sort((a, b) => a.localeCompare(b))
      .join(' ');
  }
}

interface WpwlOptions {
  locale?: string;
  applePay?: ApplePayJS.ApplePayPaymentRequest & {
    buttonType: string;
    buttonStyle: string;
    onCancel?: () => void;
  };
  googlePay?: {
    gatewayMerchantId: string;
    merchantId: string;
    currencyCode: string;
    allowedAuthMethods: google.payments.api.PaymentMethodSpecification[];
    allowedCardNetworks: google.payments.api.CardNetwork[];
    buttonType: google.payments.api.ButtonType;
    amount: string;
    onCancel?: (reason: google.payments.api.PaymentsError) => void;
    onError?: (error: Error | google.payments.api.PaymentsError) => void;
  };
  createCheckout: (json: { brand: VrBrand }) => Promise<string>;
  onReady?: () => void;
  onError?: (event: any) => void;
}

enum VrBrand {
  ApplePay = 'APPLEPAY',
  GooglePay = 'GOOGLEPAY',
}
