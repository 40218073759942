import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.dialog.html',
  styleUrls: ['./privacy.dialog.sass']
})
export class PrivacyDialog implements OnInit {
  isNonNecessaryCookiesEnabled: boolean = false;
  isNecessaryCookiesEnabled: boolean = true;

  constructor(public dialogRef: MatDialogRef<PrivacyDialog>) {
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close({
      necessaryCookiesEnabled: this.isNecessaryCookiesEnabled,
      nonNecessaryCookiesEnabled: this.isNonNecessaryCookiesEnabled
    });
  }

  onAcceptAll() {
    this.isNonNecessaryCookiesEnabled = true;
    this.isNecessaryCookiesEnabled = true;
    this.onClose();
  }


}
