import {Component, EventEmitter, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PrivacyDialog} from '../../dialogs/privacy/privacy.dialog';

@Component({
  selector: 'app-privacy-warning',
  templateUrl: './privacy-banner.component.html',
  styleUrls: ['./privacy-banner.component.sass']
})
export class PrivacyBannerComponent {

  @Output() onAccept = new EventEmitter<PrivacyPreference>();

  constructor(private dialog: MatDialog,
  ) {
  }

  onClick() {
    this.dialog.open(PrivacyDialog, {width: '310px'}).afterClosed().subscribe(result => {
      if (result != null) {
        this.onAccept.emit({
          necessaryCookiesEnabled: result.necessaryCookiesEnabled,
          nonNecessaryCookiesEnabled: result.nonNecessaryCookiesEnabled
        });
      }
    });
  }

  onAcceptAll() {
    this.onAccept.emit({
      necessaryCookiesEnabled: true,
      nonNecessaryCookiesEnabled: true
    });
  }
}

export interface PrivacyPreference {
  necessaryCookiesEnabled: boolean;
  nonNecessaryCookiesEnabled: boolean;

}
