import {Order} from '../domain/models/order/order';
import {TransactionPayment, TransactionStatus, TransactionType} from '../domain/models/order/transaction';

export class OrderUtils {
  static isPaid(order: Order): boolean {
    if (order.transactions == null) {
      return false;
    }
    return order.transactions
      .filter(t => t.type === TransactionType.Payment)
      .filter(t => t.status === TransactionStatus.Success)
      .length > 0;
  }

  static isPending(order: Order): boolean {
    if (order.transactions == null) {
      return false;
    }
    if (this.isPaid(order)) {
      return false;
    }
    return order.transactions
      .filter(t => t.type === TransactionType.Payment)
      .filter(t => t.status === TransactionStatus.Pending)
      .length > 0;
  }

  static isDelivered(order: Order): boolean {
    if (order.transactions == null) {
      return false;
    }
    return order.transactions
      .filter(t => t.type === TransactionType.Delivery)
      .filter(t => t.status === TransactionStatus.Success)
      .length > 0;
  }

  static isPendingDelivery(order: Order): boolean {
    if (order.transactions == null) {
      return false;
    }
    if (this.isDelivered(order)) {
      return false;
    }
    return order.transactions
      .filter(t => t.type === TransactionType.Delivery)
      .filter(t => t.status === TransactionStatus.Pending)
      .length > 0;
  }

  static getPaidAtDate(order: Order): Date | null {
    if (order.transactions == null) {
      return null;
    }

    const transactions = order.transactions
      .filter(t => t.type === TransactionType.Payment)
      .filter(t => t.status === TransactionStatus.Success);

    if (transactions.length == 0) {
      return null;
    }

    return new Date(`${transactions[transactions.length - 1].createdAt}Z`);
  }
}

export function isPaid(transaction: TransactionPayment) {
  return transaction.status == TransactionStatus.Success && transaction.type == TransactionType.Payment;
}

export function isPending(transaction: TransactionPayment) {
  return transaction.status == TransactionStatus.Pending && transaction.type == TransactionType.Payment;
}

export function isFailed(transaction: TransactionPayment) {
  return transaction.status == TransactionStatus.Failed && transaction.type == TransactionType.Payment;
}
