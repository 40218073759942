<div class="sp-payment">
  <ng-container *ngIf="!minimized else minimizedTpl">
    <div class="sp-payment__expanded">
      <h2 class="sp-payment__title">{{ 'PAYMENT.payUsingTerminal' | translate }}</h2>
      <ng-container *ngIf="!isPolling && !isRetry" [ngTemplateOutlet]="defaultTpl"></ng-container>
      <ng-container *ngIf="isPolling && !isRetry" [ngTemplateOutlet]="pollingTpl"></ng-container>
      <ng-container *ngIf="!isPolling && isRetry" [ngTemplateOutlet]="retryTpl"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #minimizedTpl>
  <div class="sp-payment__minimized" (click)="expand()">
    <h2 class="sp-payment__title">{{ 'PAYMENT.payUsingTerminal' | translate }}</h2>
  </div>
</ng-template>

<ng-template #defaultTpl>
  <p class="sp-payment__text">{{ 'PAYMENT.terminalInstructions' | translate }}</p>
  <button class="sp-payment__button"
          type="button"
          [ngStyle]="{background: profile?.primaryColor, color: profile?.primaryTextColor}"
          (click)="minimizeAndStartScanner()">
    {{ 'PAYMENT.scanQr' | translate }}
  </button>
  <button class="sp-payment__cancel-button" (click)="back()">
    {{ 'PAYMENT.back' | translate }}
  </button>
</ng-template>

<ng-template #pollingTpl>
  <p class="sp-payment__text">
    {{ 'PAYMENT.terminalCompleteInstructions' | translate }}
  </p>
  <div class="sp-payment__waiting-text">{{ 'PAYMENT.terminalWaiting' | translate }}</div>
  <button class="sp-payment__cancel-button" (click)="cancelPolling()">
    {{ 'PAYMENT.cancel' | translate }}
  </button>
</ng-template>

<ng-template #retryTpl>
  <p class="sp-payment__text sp-payment__text--warning">
    {{ 'ERROR.sessionExpired' | translate }}
  </p>
  <button class="sp-payment__button"
          type="button"
          [ngStyle]="{background: profile?.primaryColor, color: profile?.primaryTextColor}"
          (click)="retry()">
    {{ 'ERROR.GENERIC.retry' | translate }}
  </button>
  <button class="sp-payment__cancel-button" (click)="back()">
    {{ 'PAYMENT.cancel' | translate }}
  </button>
</ng-template>
