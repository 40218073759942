export interface PaymentMethod {
    identifier: PaymentIdentifier;
    name: string;
    url: string;
    logoUrl: string;
    sortIndex: number;
}

export enum PaymentIdentifier {
  Vipps = 'vipps',
  VippsU15 = 'vippsU15',
  VippsCheckout = 'vipps-checkout',
  Swish = 'swish',
  Demo = 'fygidemo',
  Adyen = 'adyen',
  Klarna = 'klarna',
  Mastercard = 'mastercard',
  Checkout = 'checkout',
  checkoutApplePay = 'checkout-apple-pay',
  checkoutGooglePay = 'checkout-google-pay',
  weatApplePay = 'weat-apple-pay',
  weatGooglePay = 'weat-google-pay',
  Buypass = 'buypass-vipps',
  VrApplePay = 'vr-apple-pay',
  VrGooglePay = 'vr-google-pay',
  UnzerHosted = 'unzer-hosted',
  UnzerApplePay = 'unzer-apple-pay',
  Verifone = 'verifone',
  SwedbankPay = 'swedbank-pay',
  Cash = 'cash',
  Softpay = 'softpay',
}
