import {Injectable} from '@angular/core';
import {PaymentProvider} from '../transport/payment.provider';
import {CustomerService} from './customer.service';
import {InitializePaymentResponse} from '../transport/models/payment/initialize-payment.response';
import {InitializePaymentRequest} from '../transport/models/payment/initialize-payment.request';
import {Cart} from './models/order/cart';
import {PaymentIdentifier, PaymentMethod} from './models/payment/payment-method';
import {CartUtils} from '../utils/cart.utils';
import {tap} from 'rxjs/operators';
import {GTagService} from './gtag.service';
import {CompleteOrderRequest} from '../transport/models/order/complete-order.request';
import {AgeVerficationRequest} from '../transport/models/ageverfication/ageverfication.request';
import {CompleteApplePaymentRequest} from '../transport/models/payment/complete-apple-payment-request';
import {ApplePayValidateMerchantRequest} from '../transport/models/payment/ApplePayValidateMerchantRequest';
import {CompleteGooglePaymentRequest} from '../transport/models/payment/complete-google-payment-request';
import {OrderLineRelationType} from './models/order/order-line-relation';
import {HttpErrorResponse} from '@angular/common/http';
import {Cache} from '../utils/cache.utils';
import Bugsnag from '@bugsnag/js';
import {bugsnagStartSpan} from '../bugsnag';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private directPaymentOptionCache = new Cache<PaymentMethod | false>();

  constructor(private paymentProvider: PaymentProvider,
              private customerService: CustomerService,
              private gTagService: GTagService) {
  }

  async initialize(paymentIdentifier: PaymentIdentifier, cart: Cart, deviceId?: string): Promise<InitializePaymentResponse> {
    const span = bugsnagStartSpan('payment.service:initialize');
    this.wipeAnyRelatedProductOrderLines(cart);
    const customerId = await this.customerService.getCustomerId();
    const request = new class implements InitializePaymentRequest {
      paymentProvider = paymentIdentifier;
      orderId = cart.orderId!;
      order = CartUtils.toRequest(cart);
      isApp = false;
      deviceId = deviceId;
    };
    return this.paymentProvider.initialize(customerId, request)
      .pipe(
        tap(() => this.gTagService.beginCheckout(cart.sum)),
        tap(() => span?.end()),
      )
      .toPromise();
  }

  private wipeAnyRelatedProductOrderLines(cart: Cart) {
    if (cart.orderLineRelations) {
      cart.orderLineRelations.forEach(relation => {
        if (relation.type == OrderLineRelationType.Product) {
          const relatedOrderLineIndex = cart.orderLines.findIndex(ol => ol.id == relation.relatedOrderLineId);

          if (relatedOrderLineIndex >= 0) {
            cart.orderLines.splice(relatedOrderLineIndex, 1);
          }
        }
      });
    }
  }

  async ageVerification(storeId: string, barcode: string) {
    const customerId = await this.customerService.getCustomerId();
    const request = new class implements AgeVerficationRequest {
      storeId = storeId;
      ageVerificationCode = barcode;
    };
    return this.paymentProvider.ageVerification(customerId, request).toPromise();
  }

  async refreshPaymentStatus(orderId: string) {
    return this.paymentProvider.refreshPaymentStatus(orderId).toPromise();
  }

  async complete(orderId: string, transactionId: string) {
    const request = new class implements CompleteOrderRequest {
      orderId = orderId;
      transactionId = transactionId;
    };
    return this.paymentProvider.complete(request).toPromise();
  }

  async applePayInitialize(storeHandle: string) {
    const customerId = await this.customerService.getCustomerId();
    return this.paymentProvider.applePayInitialize(storeHandle, customerId).toPromise();
  }

  async applePayPay(orderId: string, transactionId: string, tokenData: any, cardNetwork: string) {
    const customerId = await this.customerService.getCustomerId();
    const request = new class implements CompleteApplePaymentRequest {
      orderId = orderId;
      transactionId = transactionId;
      tokenData = tokenData;
      cardNetwork = cardNetwork;
    };
    return this.paymentProvider.applePayPay(customerId, request).toPromise();
  }

  async applePayValidateMerchant(orderId: string, validationURL: string) {
    const customerId = await this.customerService.getCustomerId();
    const request = new class implements ApplePayValidateMerchantRequest {
      orderId = orderId;
      url = validationURL;
    };
    return this.paymentProvider.applePayValidateMerchant(customerId, request).toPromise();
  }

  async googlePayInitialize(storeHandle: string) {
    const customerId = await this.customerService.getCustomerId();
    return this.paymentProvider.googlePayInitialize(storeHandle, customerId).toPromise();
  }

  async googlePayPay(orderId: string, transactionId: any, token: any, cardNetwork: string) {
    const customerId = await this.customerService.getCustomerId();
    const request = new class implements CompleteGooglePaymentRequest {
      orderId = orderId;
      transactionId = transactionId;
      token = token;
      cardNetwork = cardNetwork;
    };
    return this.paymentProvider.googlePayPay(customerId, request).toPromise();
  }

  async getPaymentMethods(storePublicId: string, chainPublicId: string): Promise<Array<PaymentMethod>> {
    return this.paymentProvider.getPaymentMethods(storePublicId, chainPublicId).toPromise();
  }

  async getDirectPaymentOption(storePublicId: string): Promise<PaymentMethod | false> {
    const cachedDirectPaymentOption = this.directPaymentOptionCache.get(storePublicId);
    if (cachedDirectPaymentOption || cachedDirectPaymentOption === false) {
      return cachedDirectPaymentOption;
    }

    let directPaymentOption: PaymentMethod | false;

    try {
      directPaymentOption = await this.paymentProvider.getDirectPayment(storePublicId).toPromise();
    } catch (error) {
      if (error.status == 404) {
        directPaymentOption = false;
      } else {
        throw error;
      }
    }

    this.directPaymentOptionCache.set(storePublicId, directPaymentOption);
    return directPaymentOption;
  }

  static async errorHandler(
    errorResponse: HttpErrorResponse,
    callbacks?: {
      orderIsPaid?: () => Promise<void> | void,
      productNotFound?: () => Promise<void> | void,
      dimensionOutOfStock?: (message: string) => Promise<void> | void,
    },
  ) {
    if (errorResponse.status == 400 && errorResponse.error?.detail?.includes('is paid') && callbacks?.orderIsPaid) {
      await callbacks?.orderIsPaid();
      return;
    } else if (errorResponse.error?.title?.startsWith('Product in cart request not found') && callbacks?.productNotFound) {
      await callbacks?.productNotFound();
      return;
    } else if (errorResponse.error?.error == 'DimensionOutOfStock' && callbacks?.dimensionOutOfStock) {
      await callbacks?.dimensionOutOfStock('The item is out of stock');
      return;
    }

    if (errorResponse.error?.title && errorResponse.error?.detail) {
      Bugsnag.notify(
        {name: 'Payment failed', message: `${errorResponse.error.detail}: ${errorResponse.error.title}`},
        event => event.addMetadata('Response', errorResponse),
      );
    }

    throw errorResponse;
  }
}
