import {Component, Input, OnInit} from '@angular/core';
import {OrderLine} from '../../domain/models/order/cart';
import {OrderLineType} from '../../domain/models/order/order-line';
import {StoreProfile} from '../../domain/models/store/store-profile';
import {FulfillmentOption} from '../../domain/models/store/fulfillmentOption';
import {Product} from '../../domain/models/product/product';
import {Dimension} from '../../domain/models/product/dimension';
import {Campaign} from '../../domain/models/campaign/campaign';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../domain/product.service';
import {StoreService} from '../../domain/store.service';

@Component({
  selector: 'app-cart-bundle-item',
  templateUrl: './cart-bundle-item.component.html',
  styleUrls: ['./cart-bundle-item.component.sass']
})
export class CartBundleItemComponent implements OnInit {
  OrderLineType = OrderLineType;

  @Input() orderLine?: OrderLine;
  @Input() profile?: StoreProfile;
  @Input() isBusy: boolean = false;
  @Input() dropShadow: boolean = false;
  @Input() currencyCode: string | undefined;
  @Input() cultureName: string | undefined;
  options?: FulfillmentOption[];
  storeHandle?: string;
  product?: Product;
  dimension?: Dimension;
  campaign?: Campaign;
  selectedFulfillmentOption?: FulfillmentOption;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private productService: ProductService,
              private storeService: StoreService,
  ) {
  }

  async ngOnInit() {
    this.storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    if (this.orderLine != null) {
      switch (this.orderLine.type) {
        case OrderLineType.Product:
          const selected = this.orderLine.product;
          const store = await this.storeService.getStore(this.storeHandle!);
          this.product = await this.productService.getProductById(this.storeHandle!, selected.id);
          this.dimension = this.product.dimensions.find(dim => dim.id === selected.dimensionId);
          this.options = store.fulfillmentOptions.filter(x => this.product?.fulfillmentOptions?.find(y => x.id == y.id));
          const orderLineFulfillmentId = this.orderLine.fulfillmentOptionId;
          if (orderLineFulfillmentId) {
            this.selectedFulfillmentOption = this.options.find(x => x.id == orderLineFulfillmentId);
          }
          break;
        case OrderLineType.Campaign:
          this.campaign = this.orderLine.campaign;
          break;
      }
    } else {
      this.product = undefined;
      this.dimension = undefined;
      this.campaign = undefined;
    }
  }
}
