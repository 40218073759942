import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageUtils} from '../../../utils/image.utils';
import {Category, CategoryType} from '../../../domain/models/category/category';
import {ProductService} from '../../../domain/product.service';
import {Product} from '../../../domain/models/product/product';
import {ForegroundPaths} from '../../../app-routing.module';
import {CartService} from '../../../domain/cart.service';
import {Location} from '@angular/common';
import {PaginationHandler} from '../../../domain/pagination/pagination.handler';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {CodeType, ScannerService, ScanResult} from '../../../domain/scanner.service';
import {Subscription, throwError} from 'rxjs';
import {AppComponent} from '../../../app.component';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {GTagService} from '../../../domain/gtag.service';
import {ToastrService} from 'ngx-toastr';
import {StrongPointScale} from '../../../domain/scales/strongpoint.scale';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EanInputDialog} from '../../../dialogs/ean-input/ean-input.dialog';
import {Gs1Utils} from '../../../utils/gs1.utils';
import {LocalStorageService} from 'ngx-webstorage';
import {DimensionAvailability} from '../../../domain/models/product/dimension-availability';
import {DimensionAvailabilityService} from '../../../domain/dimension-availability.service';
import {AdjustmentType} from '../../../domain/models/product/adjustmentType';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductSummary} from '../../../domain/models/product/product-summary';
import {ProductDimensionSummary} from '../../../domain/models/product/product-dimension-summary';
import {OfflineCartService} from '../../../domain/offline-cart.service';
import {OfflineDialogComponent} from '../../../dialogs/offline-dialog/offline-dialog.component';
import {SelectProductDimension} from '../../../dialogs/select-product-dimension/select-product-dimension.dialog';
import {AdjustmentTypeService} from '../../../domain/adjustment-type.service';
import {CustomerService} from '../../../domain/customer.service';
import {delay} from '../../../utils/promise.utils';
import {Microshop} from '../../../domain/models/microshop/microshop';
import {MicroshopService} from '../../../domain/microshop.service';
import {InvalidStoreDialog} from '../../../dialogs/invalid-store/invalid-store.dialog';
import {PaymentService} from '../../../domain/payment.service';
import {first} from 'rxjs/operators';
import {LoadingService} from '../../../domain/loading.service';
import {ComponentType} from '@angular/cdk/portal';
import {InvalidMicroshopDialog} from '../../../dialogs/invalid-microshop/invalid-microshop.dialog';
import {bugsnagStartSpan} from '../../../bugsnag';
import {httpUtils} from '../../../utils/http.utils';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.sass'],
})
export class StoreDetailsComponent implements OnInit, OnDestroy {

  StoreDetailsState = StoreDetailsState;
  state: StoreDetailsState = StoreDetailsState.Scan;
  store?: Store;
  microshop?: Microshop;
  profile?: StoreProfile;
  storeImage?: string;
  categories?: CategoryType[];
  adjustmentTypes: AdjustmentType[] = [];
  dimensionAvailability: DimensionAvailability[] = [];
  availabilityCount: number | undefined;
  searchQuery?: string;
  currencyCode?: string;
  haveOfflineCart = false;
  enableContinuousScanning?: boolean;
  showOfflineCartProgress = false;
  cultureName?: string;
  enableBundleMode?: boolean;
  enableBehindCounterProducts?: boolean;
  showBackToScanButton = true;
  productBrowseOnly = false;
  enableProductSearch = false;
  currentSection?: string;
  scrollShadow = false;
  enableLogin = false;

  searchHandler = new PaginationHandler<Product>(page =>
    this.productService.searchProducts(this.storeHandle!, this.searchQuery!, page),
  );

  private readonly storeHandle: string | null;
  private readonly microshopHandle: string | null;
  private scannerSubscription?: Subscription;
  private foregroundSubscription?: Subscription;
  activeAvatar = false;
  static audio?: HTMLAudioElement;
  enableScrollSpy = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private storeService: StoreService,
              private productService: ProductService,
              private cartService: CartService,
              private scannerService: ScannerService,
              private location: Location,
              private titleService: Title,
              private dialogService: MatDialog,
              private offlineCartService: OfflineCartService,
              private gTagService: GTagService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private dimensionAvailabilityService: DimensionAvailabilityService,
              private localStorageService: LocalStorageService,
              private storageService: LocalStorageService,
              private adjustmentService: AdjustmentTypeService,
              public customerService: CustomerService,
              private microshopService: MicroshopService,
              private paymentService: PaymentService,
              private loadingService: LoadingService,
  ) {
    this.storeHandle = this.route.snapshot.paramMap.get('id');
    this.microshopHandle = this.route.snapshot.paramMap.get('microshopHandle');
    this.storeService.storeId = this.storeHandle;
    document.body.addEventListener('click', this.unlockAudio);
    document.body.addEventListener('touchstart', this.unlockAudio);
  }

  async unlockAudio() {
    if (StoreDetailsComponent.audio == undefined) {
      StoreDetailsComponent.audio = new Audio('assets/audio/beep.mp3');
    }
    document.body.removeEventListener('click', this.unlockAudio);
    document.body.removeEventListener('touchstart', this.unlockAudio);
  }

  async soundNotification() {
    try {
      await this.unlockAudio();
      await StoreDetailsComponent.audio!.play().then(() => {
        StoreDetailsComponent.audio = new Audio('assets/audio/beep.mp3');
      });
    } catch (e) {
      console.log('Sound error: ' + e);
    }

  }

  async ngOnInit() {
    const span = bugsnagStartSpan('store-details:init');

    if (this.router.url.includes('/browse')) {
      this.state = StoreDetailsState.Browse;
    }

    if (this.storeHandle != null) {
      await this.initStore();
      await this.checkPendingAddCartProduct();
      this.checkDeviceId();
    }

    this.scannerService.scanner?.decoderInitialized
      // Only fire once when true
      .pipe(first(state => state, 1))
      .subscribe(state => {
        if (state && this.allowScanning()) {
          this.startScan();
        }
      });

    this.foregroundSubscription?.unsubscribe();
    this.foregroundSubscription = AppComponent.onForegroundChanged()
      .subscribe(() => {
        if (this.allowScanning()) {
          this.startScan();
        } else {
          this.stopScan();
        }
      });

    span?.end();
  }

  private async initStore() {
    this.loadingService.start();

    try {
      this.store = await this.storeService.getStore(this.storeHandle!);
    } catch (error) {
      this.loadingService.stop();
      if (error.status == 404) {
        this.openInvalidStoreDialog(InvalidStoreDialog);
      }
      throw error;
    }

    const persistedMicroshopHandle = this.microshopService.getPersistedMicroshopHandle(this.store.handle);

    if (this.microshopHandle) {
      await this.initMicroshop(this.store.handle, this.microshopHandle);
    } else if (this.state == StoreDetailsState.Browse && persistedMicroshopHandle) {
      await this.router.navigate([`store/${this.store.handle}/microshop/${persistedMicroshopHandle}/browse`], {replaceUrl: true});
    } else if (this.store.categories.length > 0) {
      this.categories = this.store.categories.filter(c => c.products.length > 0).map(c => c as Category);
      this.currentSection = this.categories[0].id;
    }

    await Promise.allSettled([
      this.checkContinuousScanningSetting(),
      this.checkProductBrowseSetting(),
      this.checkProductSearchEnabledSetting(),
      this.checkProductBehindCounterEnabledSetting(),
      this.checkProductBundleModeEnabledSetting(),
      this.storeService.isGateEnabled(this.store.handle),
      this.storeService.isDiscountCodeEnabled(this.store.handle),
      this.paymentService.getDirectPaymentOption(this.store.id),
      this.checkLoginMethods(),
    ]);

    // This hack should be removed when the backend have implemented proper support for browse only stores: ref jira SCAN-1643
    if (this.state === StoreDetailsState.Scan && this.productBrowseOnly) {
      await this.router.navigate(['browse'], {relativeTo: this.route.parent?.firstChild});
    }

    this.handleExternalId();

    try {
      this.cacheOfflineProducts(); // Don't await, fire and forget
    } catch (ex) {
      console.log(ex);
    }

    this.titleService.setTitle(`${this.store.title} | FYGI`);
    this.profile = this.store.storeProfile;
    this.currencyCode = this.store.currencyCode;
    this.cultureName = this.store.cultureName;

    this.activeAvatar = this.localStorageService.retrieve('avatar') != false;

    if (this.store.storeProfile.logoImage) {
      this.storeImage = ImageUtils.getImageUrl(this.store.storeProfile.logoImage, 200, false);
    }

    if (this.state === StoreDetailsState.Browse) {
      const initialQuery = this.route.snapshot.queryParams['search'];
      if (initialQuery != null) {
        await this.doSearch(this.storeHandle!, this.route.snapshot.queryParams['search']);
      }
    }

    this.loadingService.stop();

    if (this.store.adjustmentTypes !== null && this.store.adjustmentTypes.length > 0) {
      if (this.adjustmentService.noStoredType(this.store.handle)) {
        const productId = this.getProductIdFromPathIfAny();

        if (productId != null) {
          await this.router.navigate(ForegroundPaths.adjustmentChooserWithProduct(productId));
        } else {
          await this.router.navigate(ForegroundPaths.adjustmentChooser());
        }
      } else {
        await this.checkPendingAddCartProduct();
      }
    } else {
      await this.checkPendingAddCartProduct();
    }

    this.offlineCartService.onCartChanges(this.storeHandle!).subscribe(next => {
      let tempOfflineCart = next != null && next.orderLines.length > 0;

      if (!this.haveOfflineCart && tempOfflineCart) {
        this.showOfflineCartDialog();
      }
      this.haveOfflineCart = tempOfflineCart;
    });
  }

  private async initMicroshop(storeHandle: string, microshopHandle: string) {
    try {
      this.microshop = await this.microshopService.getMicroshop(storeHandle, microshopHandle);
      this.categories = this.microshop.categories;
      this.currentSection = this.categories[0]?.id;
    } catch (error) {
      this.loadingService.stop();
      this.microshopService.clearPersistedMicroshopHandle(storeHandle);
      if (error.status == 404) {
        this.openInvalidStoreDialog(InvalidMicroshopDialog);
      }
      throw error;
    }
    this.microshopService.persistMicroshopHandle(storeHandle, microshopHandle);
  }

  private allowScanning() {
    if (this.productBrowseOnly || !this.scannerService.scanner?.decoderInitialized.value) {
      return false;
    }

    // Ideally we should've used this.state == scan instead of checking /browse,
    // but this.state is not properly set in onScanResult callbacks
    return !this.router.url.includes('/browse') && document.visibilityState !== 'hidden' && !AppComponent.isForegroundOpen;
  }

  private async checkProductBrowseSetting() {
    if (this.store) {
      try {
        if (await this.storeService.isProductBrowseOnly(this.store.handle)) {
          this.showBackToScanButton = false;
          this.productBrowseOnly = true;
        }
      } catch (error) {
        if (error.status == 404) {
          this.showBackToScanButton = true;
        } else {
          throw error;
        }
      }
    }
  }

  private async checkContinuousScanningSetting() {
    if (this.store) {
      try {
        this.enableContinuousScanning = await this.storeService.isContinuousScanningEnabled(this.store.handle);
      } catch (error) {
        if (error.status == 404) {
          this.enableContinuousScanning = false;
        } else {
          throw error;
        }
      }
    }
  }

  private async checkProductBehindCounterEnabledSetting() {
    if (this.store) {
      try {
        this.enableBehindCounterProducts = false;
        // Tmp. disabled (await this.storeService.productBehindCounterEnabled(this.store.storeChain.id))?.value == 'true';
        // This disables the behind counter foreground menu. Does not affect delivery of behind the counter products.
      } catch (error) {
        if (error.status == 404) {
          this.enableBehindCounterProducts = false;
        } else {
          throw error;
        }
      }
    }
  }


  private async checkProductSearchEnabledSetting() {
    if (this.store) {
      try {
        if (!this.microshopHandle && await this.storeService.isProductSearchEnabled(this.store.handle)) {
          this.enableProductSearch = true;
        }
      } catch (error) {
        if (error.status == 404) {
          this.enableProductSearch = false;
        } else {
          throw error;
        }
      }
    }
  }

  private async checkProductBundleModeEnabledSetting() {
    if (this.store) {
      try {
        this.enableBundleMode = await this.storeService.isProductBundleModeEnabled(this.store.handle);
      } catch (error) {
        if (error.status == 404) {
          this.enableBundleMode = false;
        } else {
          throw error;
        }
      }
    }
  }

  private getProductIdFromPathIfAny() {
    return this.route.parent?.children[1]!.snapshot.paramMap.get('productId');
  }

  private showOfflineCartDialog() {
    this.dialogService.open(OfflineDialogComponent, {
      width: '250px',
      data: this.store?.adjustmentTypes
    }).afterClosed().toPromise();
  }

  private cacheOfflineProducts() {
    return new Promise(async (resolve, reject) => {
      await this.productService.syncOfflineProducts(this.storeHandle!);
    });
  }

  ngOnDestroy(): void {
    this.scannerSubscription?.unsubscribe();
    this.foregroundSubscription?.unsubscribe();
  }

  async startScan() {
    let tutorialText: string | undefined;

    if (this.storeHandle != null) {
      if (this.storageService.retrieve("cart") == null) {
        tutorialText = await this.translateService.get('SCANNER.SCANPRODUCTS.description').toPromise();
      }
    }
    this.scannerSubscription?.unsubscribe();
    this.scannerSubscription = this.scannerService
      .onScanResult([CodeType.Code128, CodeType.EAN8, CodeType.EAN13, CodeType.QR, CodeType.UPCA, CodeType.DATA_MATRIX], tutorialText)
      .subscribe(async result => {
        this.scannerSubscription?.unsubscribe();
        const storeHandle = this.storeHandle;

        await this.soundNotification();

        if (storeHandle) {
          if (result.symbologyName == CodeType.QR && result.barcodeData.includes('/microshop/')) {
            await this.handleMicroshop(result);
          } else if (result.symbologyName === CodeType.QR && StoreDetailsComponent.isEpcUrl(result.barcodeData)) {
            result.barcodeData = this.parseEpcUrl(result.barcodeData);
            await this.handleDefaultBarcode(storeHandle, result);
          } else if (result.symbologyName === CodeType.QR && StrongPointScale.isValidInput(result.barcodeData)) {
            const weight = StrongPointScale.parseWeightInGrams(result.barcodeData);
            await this.router.navigate(ForegroundPaths.weighProduct(weight));
            return;
          } else if (result.symbologyName == CodeType.QR && result.barcodeData.includes('fygi.app')) {
            await this.handleFygiQr(result);
          } else if (result.symbologyName == CodeType.QR && /^https:\/\/qr(-mt)?\.vipps\.no/.test(result.barcodeData)) {
            window.location.href = result.barcodeData;
            return;
          } else if (result.symbologyName == CodeType.DATA_MATRIX) {
            await this.handleDataMatrixCode(storeHandle, result);
          } else if (this.isGermanPluProduct(result)) {
            const barcode = this.parsePluBarcode(result.barcodeData);
            const price = this.parsePluPrice(result.barcodeData);
            const newPrice = Number(price);
            await this.handlePLUBarcode(storeHandle, barcode, newPrice);
          } else if (this.isGermanButcherProduct(result)) {
            const barcode = '0001';
            const price = this.parsePluPrice(result.barcodeData);
            const newPrice = Number(price);
            await this.handlePLUBarcode(storeHandle, barcode, newPrice);
          } else if (this.isGermanMagazineCode2(result)) {
            const barcode = result.barcodeData.slice(0, 8);
            const price = result.barcodeData.slice(8, 12);
            const newPrice = Number(price);
            await this.handlePLUBarcode(storeHandle, barcode, newPrice);
          } else if (this.isGermanMagazineCode(result)) {
            const barcode = result.barcodeData.slice(0, 8);
            const price = result.barcodeData.slice(8, 12);
            const newPrice = Number(price);
            await this.handlePLUBarcode(storeHandle, barcode, newPrice);
          } else if (this.isGermanMettlerWeightCode(result)) {
            let parts = result.barcodeData.split('|');
            let plu = parts[2];
            let price = parts[4];
            const newPrice = Number(price);
            await this.handlePLUBarcode(storeHandle, plu, newPrice);
          } else if (result.symbologyName == CodeType.UPCA) {
            result.barcodeData = '0' + result.barcodeData;
            await this.handleDefaultBarcode(storeHandle, result);
          } else if (result.symbologyName == CodeType.Code128 && result.barcodeData === 'kebabfriday') {
            await this.router.navigate(ForegroundPaths.kebabFriday());
          } else {
            await this.handleDefaultBarcode(storeHandle, result);
          }
        }

        if (this.allowScanning()) {
          await this.startScan();
        }
      });
  }

  private async handleFygiQr(result: ScanResult) {
    const scannedData = result.barcodeData;
    const pathParts = scannedData?.split('/');
    const lastPathPart = pathParts[pathParts.length - 1];

    try {
      const product = await this.productService.getProductByHandle(this.storeHandle!, lastPathPart);
      await this.cartService.add(this.storeHandle!, product);
      await delay(1500); // Delay next scan
    } catch (reason) {
      if (reason instanceof HttpErrorResponse && (reason.status == 404)) {
        await this.handleUnknownProduct(result);
      } else {
        throwError(reason);
      }
    }
  }

  private async handleMicroshop(result: ScanResult) {
    const newUrl = new URL(result.barcodeData);
    if (window.location.hostname != newUrl.hostname) {
      window.location.href = result.barcodeData;
    } else {
      await this.router.navigate([newUrl.pathname]);
    }
  }

  private isGermanPluProduct(result: ScanResult) {
    return result.symbologyName == CodeType.EAN13 && result.barcodeData.startsWith('210');
  }

  private isGermanButcherProduct(result: ScanResult) {
    return result.symbologyName == CodeType.EAN13 && result.barcodeData.startsWith('220001');
  }

  private isGermanMagazineCode2(result: ScanResult) {
    return result.symbologyName == CodeType.EAN13 && result.barcodeData.startsWith('419');
  }

  private isGermanMagazineCode(result: ScanResult) {
    return result.symbologyName == CodeType.EAN13 && result.barcodeData.startsWith('414');
  }

  private isGermanMettlerWeightCode(result: ScanResult) {
    return result.symbologyName == CodeType.QR && result.barcodeData.includes('|');
  }

  private static isEpcUrl(barcodeData: string) {
    return barcodeData.startsWith('urn:epc:id:sgtin:');
  }

  private async handlePLUBarcode(storeHandle: string, barcode: string, price: number) {
    const product = await this.productService.findByBarcode(storeHandle, barcode);
    let dimensionId = product.dimensions.find(t => t.barcode == barcode)?.id;
    if (!this.enableContinuousScanning) {
      await this.router.navigate(ForegroundPaths.productWithDimension(product.handle, dimensionId));
      return;
    }
    await this.checkAvailability(product);
    await this.cartService.add(storeHandle, product, dimensionId, 1, undefined, price);
    await new Promise(resolve => setTimeout(resolve, 3500));
  }


  private async handleDefaultBarcode(storeHandle: string, result: ScanResult) {
    try {
      const product = await this.productService.findByBarcode(storeHandle, result.barcodeData);
      let dimensionId = product.dimensions.find(t => t.barcode == result.barcodeData)?.id;
      if (!this.enableContinuousScanning) {
        await this.router.navigate(ForegroundPaths.productWithDimension(product.handle, dimensionId));
        return;
      }
      await this.checkAvailability(product);
      if (product.dimensions.length > 1 && product.dimensions.filter(d => d.barcode.length <= 5).length >= 1 && this.enableBundleMode) {
        const dimensions = product.dimensions.filter(d => d.barcode.length <= 5 || d.id == dimensionId);
        const selectedDimension = await this.dialog.open(SelectProductDimension, {
          data: dimensions
        }).afterClosed().toPromise();
        await this.cartService.add(storeHandle, product, selectedDimension);
      } else {
        await this.cartService.add(storeHandle, product, dimensionId);
        await new Promise(resolve => setTimeout(resolve, 3500));
      }

    } catch (error) {
      if (httpUtils(error).isAbortError()) {
        let offlineProduct: [ProductSummary, ProductDimensionSummary] = this.productService.getOffLineProduct(storeHandle, result.barcodeData);
        await this.offlineCartService.addOfflineProduct(storeHandle, offlineProduct[0], offlineProduct[1]);
      } else if (httpUtils(error).isStatus(404)) {
        await this.handleUnknownProduct(result);
      } else {
        throwError(error);
      }
    }
  }

  private async checkAvailability(product: Product) {
    const itemCount = await this.cartService.getItemCount(this.storeHandle!, product, product.dimensions[0]);
    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(this.storeHandle!, product.id);
    this.availabilityCount = this.dimensionAvailability[0].count;
    if (this.store != null && this.dimensionAvailability[0].trackStock && this.availabilityCount > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
  }

  private async handleUnknownProduct(result: ScanResult) {
    const symbology = await this.getFriendlySymbologyName(result.symbologyName);
    const message = symbology + await this.translateService.get('SCANNER.ERROR.unrecognized').toPromise();
    this.toastr.warning(message, undefined, {
      timeOut: 3000,
      easeTime: 100,
      positionClass: 'toast-bottom-center'
    });
    await new Promise(resolve => setTimeout(resolve, 5000));
  }

  private async getFriendlySymbologyName(symbology: CodeType): Promise<string> {
    switch (symbology) {
      case CodeType.QR:
        return await this.translateService.get('SCANNER.ERROR.QR').toPromise();
      case CodeType.Code128:
      case CodeType.EAN8:
      case CodeType.EAN13:
      case CodeType.UPCA:
      case CodeType.DATA_MATRIX:
        return await this.translateService.get('SCANNER.ERROR.BARCODE').toPromise();
    }
  }

  private async handleDataMatrixCode(storeHandle: string, result: ScanResult) {
    let data = Gs1Utils.parse(result.barcodeData);
    const product = await this.productService.findByArticleSerialNumber(storeHandle, data.gtin, data.serialNumber).catch(_ => undefined);
    if (product) {
      const itemCount = await this.cartService.getItemCount(this.storeHandle!, product.product, product.product.dimensions[0]);
      this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(this.storeHandle!, product.product.id);
      this.availabilityCount = this.dimensionAvailability[0].count;
      if (this.store != null && this.dimensionAvailability[0].trackStock && this.availabilityCount > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {

        await this.cartService.add(storeHandle, product.product, undefined, undefined, product.itemId);
        await new Promise(resolve => setTimeout(resolve, 5000));
      } else {
        const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
        this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      }
    } else {
      const symbology = await this.getFriendlySymbologyName(result.symbologyName);
      const message = symbology + await this.translateService.get('SCANNER.ERROR.unrecognized').toPromise();
      this.toastr.warning(message, undefined, {
        timeOut: 3000,
        easeTime: 100,
        positionClass: 'toast-bottom-center'
      });
      await new Promise(resolve => setTimeout(resolve, 5000));
    }

  }

  stopScan() {
    this.scannerSubscription?.unsubscribe();
  }

  /** @deprecated See ToolbarComponent */
  async onBackClicked() {
    if (this.router.url.endsWith('browse')) {
      await this.router.navigate(['..'], {relativeTo: this.route});
      return;
    }
    await this.router.navigate(['/'], {relativeTo: this.route});
  }

  async openProduct(productHandle: string) {
    await this.router.navigate(ForegroundPaths.product(productHandle));
  }

  async doSearch(storeHandle: string, query: string) {
    this.searchQuery = query;
    this.searchHandler.clear();
    if (query.length < 1) {
      return this.endSearch();
    }
    if (this.searchHandler.isBusy) {
      this.searchHandler.clear();
    }
    await this.searchHandler.getNext();
    this.updateUrlWithSearchQuery(query);
  }

  async endSearch() {
    this.searchQuery = undefined;
    this.searchHandler.clear();
    this.updateUrlWithSearchQuery();
    this.onScroll();
  }

  async onSearchScrolled(event: any) {
    if (this.searchHandler.isBusy) {
      return;
    }
    const bottomTriggerPoint = 150;
    this.scrollShadow = event.target.scrollTop > 5;
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - bottomTriggerPoint)) {
      if (this.searchHandler.hasNext()) {
        await this.searchHandler.getNext();
      }
    }
  }

  private updateUrlWithSearchQuery(query?: string) {
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {search: query}
    }).toString();
    this.location.go(url);
  }

  async quickAddToCart(storeHandle: string, product: Product) {
    const span = bugsnagStartSpan('product-search:quickAddToCart');
    this.storageService.store('cart', true);
    const isShipmentDefault = product.fulfillmentOptions?.find(option => option.type === 'shipment')?.default;

    if (isShipmentDefault != undefined && !isShipmentDefault && this.store) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store.handle, product);
      span?.end();
      return;
    }

    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(storeHandle, product.id);
    this.availabilityCount = this.dimensionAvailability[0].count;
    const itemCount = await this.cartService.getItemCount(storeHandle, product, product.dimensions[0]);

    if (this.store != null && this.dimensionAvailability[0].trackStock && this.availabilityCount > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
      await this.cartService.add(storeHandle, product);
      this.gTagService.quickAdd(product.dimensions[0], product);
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
    span?.end();
  }

  async showEANInputDialog() {
    const barcode = await this.dialog.open(EanInputDialog).afterClosed().toPromise();

    if (barcode != null && barcode.toLowerCase() === 'kebabfriday') {
      await this.router.navigate(ForegroundPaths.kebabFriday());
    } else if (barcode != null) {
      const product = await this.productService.findByBarcode(this.storeHandle!, barcode).catch(_ => undefined);

      if (product) {
        if (!this.enableContinuousScanning) {
          await this.router.navigate(ForegroundPaths.product(product.handle));
          return;
        }

        const isShipmentDefault = product.fulfillmentOptions?.find(option => option.type === 'shipment')?.default;
        if (isShipmentDefault != undefined && !isShipmentDefault && this.store) {
          await this.router.navigate(ForegroundPaths.empty());
          await delay(80);
          await this.cartService.add(this.store.handle, product);
          return;
        }

        const itemCount = await this.cartService.getItemCount(this.storeHandle!, product, product.dimensions[0]);
        this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(this.storeHandle!, product.id);
        this.availabilityCount = this.dimensionAvailability[0].count;
        if (this.store != null && this.dimensionAvailability[0].trackStock && this.availabilityCount > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
          let dimensionId = product.dimensions.find(t => t.barcode == barcode)?.id;
          if (product.dimensions.filter(d => d.barcode.length <= 5).length >= 1 && this.enableBundleMode) {
            const dimensions = product.dimensions.filter(d => d.barcode.length <= 5 || d.id == dimensionId);
            const selectedDimension = await this.dialog.open(SelectProductDimension, {
              data: dimensions
            }).afterClosed().toPromise();
            await this.cartService.add(this.storeHandle!, product, selectedDimension);
          } else {
            await this.cartService.add(this.storeHandle!, product, dimensionId);
            await new Promise(resolve => setTimeout(resolve, 3500));
          }
        } else {
          const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
          this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
        }
      } else {
        const error = await this.translateService.get('SCANNER.ERROR.notFound').toPromise();
        this.toastr.error(error, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      }
    }
  }

  setAvatarStorage() {
    if (this.localStorageService.retrieve('avatar') == null || undefined) {
      this.localStorageService.store('avatar', false);
      this.activeAvatar = false;
    }
    this.localStorageService.retrieve('avatar');
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  private async checkPendingAddCartProduct() {
    const pendingProduct = this.localStorageService.retrieve('pendingAddCartProduct');
    this.localStorageService.clear('pendingAddCartProduct');
    if (pendingProduct != null) {
      const product = await this.productService.getProductByHandle(this.storeHandle!, pendingProduct);
      await this.cartService.add(this.storeHandle!, product);
    }
    this.localStorageService.retrieve('avatar');
  }

  async tryCartConnect() {
    this.showOfflineCartProgress = true;
    let offlineCart = this.offlineCartService.getCart(this.storeHandle!);

    let products: Product[] = [];

    try {
      for (let orderLine of offlineCart!.orderLines) {
        let product = await this.productService.findByBarcode(this.storeHandle!, orderLine.dimension.barcode);
        products.push(product);
      }

      await this.cartService.addOfflineBatch(this.storeHandle!, products, offlineCart!.orderLines!);
      await this.offlineCartService.clearCart(this.storeHandle!);
    } catch (e) {

    }
    this.showOfflineCartProgress = false;
  }

  private parseEpcUrl(barcodeData: string) {
    let parts = barcodeData.split(':');
    let lastPart = parts[4];
    let numbers = lastPart.split('.');
    let wholeThing = numbers[0] + numbers[1].slice(1);
    return wholeThing + this.eanCheckDigit(wholeThing);
  }

  private parsePluBarcode(barcodeData: string) {
    return barcodeData.slice(3, 6);
  }

  private parsePluPrice(barcodeData: string) {
    return barcodeData.slice(7, 12);
  }

  eanCheckDigit(s: string) {
    let result = 0;
    let i = 1;
    for (let counter = s.length - 1; counter >= 0; counter--) {
      result = result + parseInt(s.charAt(counter)) * (1 + (2 * (i % 2)));
      i++;
    }
    return (10 - (result % 10)) % 10;
  }


  async toggleBrowseMode() {
    if (this.state == StoreDetailsState.Browse) {
      await this.router.navigate([`store/${this.store?.handle}`]);
    } else {
      await this.router.navigate([`store/${this.store?.handle}/browse`]);
    }
  }

  async openBehindMenu() {
    await this.router.navigate(ForegroundPaths.behindCounter());
  }

  openLogin() {
    this.customerService.openLoginDialog();
  }

  onSectionChange(sectionId: string) {
    if (this.enableScrollSpy && sectionId) {
      this.currentSection = sectionId;
      const btnElement = document.getElementById(`btn_${sectionId}`);

      if (btnElement) {
        document.getElementById('categoryDetails')?.scrollTo({left: btnElement.offsetLeft - 20, behavior: 'smooth'});
      }
    }
  }

  async scrollToSection(sectionId: string): Promise<void> {
    if (sectionId) {
      this.currentSection = sectionId;
      this.enableScrollSpy = false;
      const element = document.getElementById(sectionId);

      if (element) {
        document.body.scrollTo({top: element.offsetTop - 115, behavior: 'smooth'});
        await delay(1500);
      }

      this.enableScrollSpy = true;
    }
  }

  @HostListener('body:scroll')
  onScroll() {
    const scrollTop = document.body.scrollTop;
    this.scrollShadow = scrollTop > 115;
  }

  private openInvalidStoreDialog(dialogComponent: ComponentType<unknown>) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    this.dialog.open(dialogComponent, dialogConfig);
  }

  @HostListener('document:visibilitychange')
  async visibilityStateChanged() {
    if (this.productBrowseOnly) {
      return;
    }

    if (document.visibilityState === 'hidden') {
      this.stopScan();
    } else if (this.allowScanning()) {
      await this.startScan();
    }
  }

  private handleExternalId() {
    const externalId = this.route.snapshot.queryParams['externalId'];
    if (externalId && this.store) {
      this.storeService.setExternalId(this.store.handle, externalId);
    }
  }

  private async checkLoginMethods() {
    if (this.store) {
      const loginMethods = await this.customerService.getLoginMethods(this.store.handle);
      this.enableLogin = loginMethods && loginMethods.length > 0;
    }
  }

  private checkDeviceId() {
    const deviceId = this.route.snapshot.queryParams['deviceId'];
    if (deviceId) {
      this.toastr.info(this.translateService.instant('PAYMENT.terminalScannedNotification'), undefined, {
        timeOut: 5000,
        easeTime: 100,
        positionClass: 'toast-bottom-center',
      });
    }
  }
}

enum StoreDetailsState {
  Scan,
  Browse,
}
