<mat-dialog-content>
  <h2>{{'COOKIE.ManageCookies' | translate }}</h2>
  <p>{{'COOKIE.descriptionDialog' | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="privacy-strictly">
    <a class="privacy-strictly-text">
      {{'COOKIE.necessaryCookies' | translate }}
    </a>

    <label class="switch">
      <input type="checkbox" disabled [checked]="isNecessaryCookiesEnabled"
             (change)="isNecessaryCookiesEnabled = !isNecessaryCookiesEnabled">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="privacy-none">
    <a class="privacy-none-strictly-text">
      {{'COOKIE.NonNecessaryCookies' | translate }}
    </a>

    <label class="switch">
      <input type="checkbox" [checked]="isNonNecessaryCookiesEnabled"
             (change)="isNonNecessaryCookiesEnabled = !isNonNecessaryCookiesEnabled">
      <span class="slider round"></span>
    </label>
  </div>
  <div class="privacy-submit">
    <a class="privacy-submit-save" (click)="onClose()">{{'COOKIE.savePreferences' | translate }}</a>
    <button class="privacy-submit-accept" (click)="onAcceptAll()">{{'COOKIE.acceptAll' | translate }}</button>
  </div>
</mat-dialog-actions>
